import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/hero.png"
import thumbnailEvent from "../../static/images/feature-mute.png"
import thumbnailHotkey from "../../static/images/feature-hotkey.png"
import thumbnailSwitch from "../../static/images/feature-switch.png"
import thumbnailLogin from "../../static/images/feature-login.png"
import iconAppStore from "../../static/images/icon-app-store.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Microphone Management" />

    <div className={"page-header home"}>
      <h1>Take Control of the Mic</h1>
      <p>
        Mikrofon gives you the power to control any microphone at the system
        level so you can use it with any chat platform.
      </p>
      <img alt={"Dashboard"} src={featureImage} />
    </div>

    <div className={"container"}>
      <div className={"features"}>
        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Mute"} src={thumbnailEvent} />
              </div>
            </div>

            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Mute on your terms</h2>
                <p>
                  Mikrofon gives you two ways to control your mic. You can
                  toggle your mute status, or to hold to unmute.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Global Hot Key</h2>
                <p>
                  Choose the hotkey that makes the most sense to you. Mikrofon
                  will handle the rest.
                </p>
              </div>
            </div>

            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Hotkey"} src={thumbnailHotkey} />
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Switch"} src={thumbnailSwitch} />
              </div>
            </div>

            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Don't drop the mic</h2>
                <p>
                  Mikrofon will apply your settings to the active microphone –
                  even when you switch which microphone is active.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Start at login</h2>
                <p>
                  Mikrofon can be there when you boot up your mac to prevent
                  anyone from listening in.
                </p>
              </div>
            </div>

            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Team"} src={thumbnailLogin} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={"call-to-action"}>
      <div className={"container"}>
        <div className={"call-to-action__content"}>
          <h2>Get it on the Mac App Store</h2>
          <p>
            Download today to discover how easy microphone management can be.
          </p>
        </div>

        <div className={"button"}>
          <a
            href="https://apps.apple.com/us/app/mikrofon/id1518516143?mt=12"
            target="_blank"
            rel="noreferrer"
          >
            <img alt={"Available on the App Store"} src={iconAppStore} />
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
